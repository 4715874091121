import React, { useContext, useEffect, useState } from 'react';
import { User } from '../../../Classes/User';
import { UserContext } from '../../../Context/UserContext';
import { API_EmployeeLogin, API_IsTemp } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import * as styles from '../Login.module.scss';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../Loading/Loading';
import Input from '../../Input/input';
import { Encrypt, EncryptData } from '../../../Services/Crypto';
import { Button } from '../../../Views/Portal/Courses/Components/Button/Button';

const EmployeeLoginForm: React.FC = () => {
  const { setUser } = useContext(UserContext);
  const [userForm, setUserForm] = useState<User>({} as User);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setMessage("");
  }, [userForm]);

  const Submit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (userForm.Email && userForm.Password)
      await Fetch(API_IsTemp, userForm.Email).then(async (isTemp: number) => {
        if (isTemp > 0)
          await Fetch(API_EmployeeLogin, { ...userForm, Password: isTemp === 1 ? userForm.Password : Encrypt(userForm.Password), Force: 0 }).then(u => {
            if (u.User)
              if (u.User.ID) {
                if (u.User.isVerified) {
                  localStorage.setItem("User", EncryptData(u.Token));
                  setUser(u.User);
                  setUserForm({} as User);
                } else {
                  setMessage("This Account is no longer available");
                }
              } else
                setMessage("The Email or Password provided was incorrect");
            else
              setMessage("The Email or Password provided was incorrect");
          }).catch((ex) => {
            setMessage(ex.message);
          });
        else
          setMessage("The Email or Password provided was incorrect");
      });
    else
      setMessage("Please fill in all required fields");
    setLoading(false);
  }

  return (<form className={styles.Form} onSubmit={(e) => Submit(e)}>
    <div className={`${styles.FormInner}`}>
      <h1>Employee Login</h1>
      <p>Login here as an Employee to manage your Emergency Contacts, Company Profile, Report Absences and Request Holidays</p>

      {loading ? <Loading /> : <>
        <Input Required Placeholder="" Type="text" Value={userForm.Email} Label="Email" Icon={faEnvelope} OnChange={(v) => setUserForm({ ...userForm, Email: v })} />
        <Input Required Placeholder="" Type="password" Value={userForm.Password} Label="Password" Icon={faLock} OnChange={(v) => setUserForm({ ...userForm, Password: v })} />

        {message ? <div className={styles.Message}>
          {message}
        </div> : <></>}

        <Button Disabled={!userForm.Password || !userForm.Email} Type="submit" Color="Theme">Login</Button>
      </>}
    </div>
  </form>);
};

export default EmployeeLoginForm;
