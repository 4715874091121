import React from 'react';
import EmployeeLogin from '../../Views/Portal/Login/Employee/Employee';
import Layout from '../../components/Layout/layout';

const LoginPage: React.FC = () => {
  return (
    <Layout NoFooter={true} NoHeader={true}>
      <EmployeeLogin />
    </Layout>
  );
};

export default LoginPage;
