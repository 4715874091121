import React from 'react';
import LoginMaster from '../../../../components/LoginMaster/LoginMaster';
import EmployeeLoginForm from '../../../../components/Login/Employee/EmployeeLogin';

const EmployeeLogin: React.FC = () => {
  return (<>
    <LoginMaster backPath='/Login'>
      <EmployeeLoginForm />
    </LoginMaster>
  </>
  );
};
export default EmployeeLogin;
